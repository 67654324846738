import React from 'react';
import './TeamMember.css';
import { useInView } from 'react-intersection-observer';
import Educate from "./img/Educate.jpg";
import Enrich from "./img/Enrichjpg.jpg";
import Empower from "./img/Empower.jpg";

const TeamMember = () => {
  const { ref: card1Ref, inView: card1InView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: card2Ref, inView: card2InView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: card3Ref, inView: card3InView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className='Doctor-text-TM'>
      
      {/* <h1 className='Doctor-text text-start ms-5'><b>Team Members</b></h1>
      <div className="yellow-line-TM ms-5 "></div> */}
      
      <div className="container">
        <div className="row">
          <div ref={card1Ref} className={`col-lg-4 col-md-6 mb-4 card-TM-container ${card1InView ? 'animate' : ''}`}>
            <div className="card-TM">
              <img src={Educate} className="card-img-top" alt="" />
              <div className="card-body">
                <h5><b>Alcohol Addiction Treatment</b></h5>
                <p className='text-start'><b>Description: Alcohol addiction is one of the most common issues treated in rehabilitation centers. This treatment focuses on helping individuals manage withdrawal symptoms, control cravings, and make lifestyle changes to prevent relapse.</b></p>
              <p className='text-start'> Treatment: Detoxification, counseling, medication, group therapy, and support groups like Alcoholics Anonymous (AA) are often used. Behavioral therapies help individuals understand their triggers and develop healthier coping mechanisms.</p> 
              <p className='text-start'> Suggested Photo: Show a serene setting or peaceful therapy session, symbolizing support and recovery.</p> 

              </div>
            </div>
          </div>
          <div ref={card2Ref} className={`col-lg-4 col-md-6 mb-4 card-TM-container ${card2InView ? 'animate' : ''}`}>
            <div className="card-TM">
              <img src={Enrich} className="card-img-top" alt="" />
              <div className="card-body">
                <h5><b>Drug Addiction Treatment</b></h5>
                <p className='text-start'><b>Description: Drug addiction treatment aims to help individuals overcome dependence on substances like opioids, cocaine, and other drugs. It's a multi-step process that includes detox, therapy, and aftercare support.</b></p>
                <p className='text-start'><b>Treatment: Treatment usually involves medically-assisted detox, one-on-one counseling, behavioral therapy, and support groups. Family counseling is also common to help repair relationships impacted by addiction.</b></p>
                <p className='text-start'><b>Suggested Photo: Display an image of a counseling session, or a supportive group environment to signify a positive, supportive community.</b></p>
                </div>
            </div>
          </div>
          
          
          <div ref={card3Ref} className={`col-lg-4 col-md-6 mb-4 card-TM-container ${card3InView ? 'animate' : ''}`}>
            <div className="card-TM">
              <img src={Empower} className="card-img-top" alt="Dr. Shweta Goswami" />
              <div className="card-body">
                <h5><b>Behavioral Addiction Treatment</b></h5>
                <p className='text-start'><b>Description: Behavioral addictions (such as gambling, internet, or gaming addiction) are treated to help individuals manage compulsive behavior without substances. This treatment focuses on behavioral modification and therapy.</b></p>
                <p className='text-start'><b>Treatment: Cognitive-behavioral therapy (CBT), mindfulness practices, and group counseling help individuals recognize behavior patterns, build healthier habits, and develop self-control.</b></p>
                <p className='text-start'><b>Suggested Photo: An image depicting a therapist and patient engaging in discussion or a relaxing activity can represent this therapy.</b></p>

              </div>
              
            </div>
          </div>
        </div>
      </div>
      
    </div>
    
  );
};

export default TeamMember;
