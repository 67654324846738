import React, { useEffect } from 'react';
import './Facility.css'
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import Bannerimage4 from "./img/Bannerimage4.jpg";
import Bannerimage3 from "./img/Bannerimage3.jpg";
import rooms from "./img/rooms.jpg";
import Counseling from "./img/Counseling.webp";
import hands from "./img/hands.webp";
import Dining from "./img/Dining.jpg";
import Familly from "./img/Familly.jpg";
import Recovery from "./img/Recovery.webp";

const Facility = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <div className="facility-container"><br /><br />
            <h1 className="facility-heading" data-aos="fade-up">Our World-Class Facility</h1>

            {/* Treatment Facilities */}
            <section className="facility-details" data-aos="fade-up">
                <h2>Treatment Facilities</h2>
                <p>
                    Our treatment options are comprehensive and personalized to cater to the specific needs of each individual. We offer advanced detox programs, medication-assisted treatments, psychological counseling, and group therapy in a comfortable and supportive environment.
                </p>
                <img src={Bannerimage4} alt="Treatment Room" className="facility-image" />
            </section>

            {/* Recreational and Therapeutic Facilities */}
            <section className="facility-details" data-aos="fade-up">
                <h2>Recreational & Therapeutic Facilities</h2>
                <p>
                    Our center promotes healing through relaxation and rejuvenation. We provide yoga and meditation rooms, nature therapy zones, art and music therapy, and a peaceful environment to help patients heal holistically.
                </p>
                <img src={Bannerimage3} alt="Therapy Room" className="facility-image" />
            </section>

            {/* Medical Support and Detox Facilities */}
            <section className="facility-details" data-aos="fade-up">
                <h2>Medical & Detox Facilities</h2>
                <p>
                    Our center is equipped with advanced medical technologies to ensure constant monitoring and care. We offer 24/7 medical support, emergency care, detox rooms, and personalized health plans for each individual.
                </p>
                <img src={rooms} alt="Medical Room" className="facility-image" />
            </section>

            {/* Counseling and Therapy Areas */}
            <section className="facility-details" data-aos="fade-up">
                <h2>Counseling & Therapy</h2>
                <p>
                    Our licensed counselors provide one-on-one therapy, group therapy, and family counseling. Specialized therapies are designed to help you heal from addiction, trauma, and mental health issues.
                </p>
                <img src={Counseling} alt="Counseling Room" className="facility-image" />
            </section>

            {/* Hygiene and Safety */}
            <section className="facility-details" data-aos="fade-up">
                <h2>Hygiene & Safety</h2>
                <p>
                    Our facility adheres to the highest standards of hygiene and cleanliness. We have strict sanitation procedures to ensure that all areas, from rooms to therapy spaces, are safe and hygienic.
                </p>
                <img src={hands} alt="Hygiene and Safety" className="facility-image" />
            </section>

            {/* Dining and Nutrition */}
            <section className="facility-details" data-aos="fade-up">
                <h2>Dining & Nutrition</h2>
                <p>
                    Our nutrition experts ensure that each meal is balanced, healthy, and tailored to the specific needs of individuals in recovery. We offer delicious, nutritious meals to help with detox and overall well-being.
                </p>
                <img src={Dining} alt="Dining Area" className="facility-image" />
            </section>

            {/* Aftercare and Long-Term Recovery */}
            <section className="facility-details" data-aos="fade-up">
                <h2>Aftercare & Long-Term Recovery</h2>
                <p>
                    Our aftercare program is designed to provide continuous support after your treatment. We offer follow-up counseling, group therapy, and a recovery alumni network to ensure long-term success and support.
                </p>
                <img src={Recovery} alt="Aftercare" className="facility-image" />
            </section>

            {/* Family and Visitor Facilities */}
            <section className="facility-details" data-aos="fade-up">
                <h2>Family & Visitor Facilities</h2>
                <p>
                    We believe in the importance of family support during the recovery process. Our visitor areas are designed for family members to visit comfortably. We also provide family counseling sessions to help loved ones support recovery.
                </p>
                <img src={Familly} alt="Family and Visitor Area" className="facility-image" />
            </section>
        </div>
    );
};

export default Facility;
