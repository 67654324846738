import React from 'react';
import { Link } from "react-router-dom";
import TeamMember from './TeamMember';
import './CenterContent.css'; // Import CSS file

const CenterContent = () => {
  return (
    <div className="content-wrapper">
      <br /><br />
      <h2 className="content-title">Maa Sadhana Nasha Mukti Kendra</h2>
      <p className="content-subtitle">Your Path to a New Life</p>
      
      <p className="content-description">
        A de-addiction center is a place where individuals receive support to break free from addiction <br /> and embrace a healthy, substance-free life. At Maa Sadhana Nasha Mukti Kendra, we focus on the physical, <br />mental, and emotional well-being of each person, offering a holistic approach to recovery.
      </p>
      
      <h3 className="content-section-title">Key Services at Our Center:</h3>
      <ul className="content-service-list">
        <li><strong>Individual Assessment and Counseling:</strong> We begin by understanding each person’s addiction history and health condition. This helps us create a personalized recovery plan, providing tailored support to each individual.</li>
        <li><strong>Detoxification (Cleansing Process):</strong> A medically supervised detox process cleanses the body of addictive substances, eliminating toxins and preparing for a fresh start.</li>
        <li><strong>Psychological Counseling:</strong> Our trained counselors help build mental and emotional strength, instilling confidence and stability.</li>
        <li><strong>Yoga and Meditation Programs:</strong> Through yoga and meditation, we help individuals find inner peace, reduce stress, and build resilience.</li>
        <li><strong>Life Skills Training:</strong> We teach practical skills such as communication, stress management, and time management for a balanced life.</li>
        <li><strong>Family Support and Counseling:</strong> Addiction affects the entire family. We offer family counseling to foster a supportive environment.</li>
        <li><strong>Follow-up Programs and Support Groups:</strong> Regular follow-up sessions and support groups provide ongoing encouragement to maintain a recovery journey.</li>
      </ul>

      <h3 className="content-section-title">Our Mission:</h3>
      <p className="content-description">
        We aim to help every individual build a new life—one that is healthy, fulfilling, and free from addiction, where they can achieve their dreams and bring happiness to their families.
      </p>

      {/* <Link to="/" className="btn btn-warning ms-2 link-button">Know More / Our Inspiration</Link><br /> */}
      <TeamMember />
    </div>
  );
};

export default CenterContent;
