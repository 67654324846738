import React, { useState } from 'react';
import './ImageGallery.css';
// import image1 from "../components/img/image1.jpg"
// import image2 from "../components/img/image2.jpg"
// import image3 from "../components/img/image3.jpg"
// import image4 from "../components/img/image4.jpg"
// import image5 from "../components/img/image5.jpg"
// import image6 from "../components/img/image6.jpg"
// import image7 from "../components/img/image7.jpg"
// import image8 from "../components/img/image8.jpg"
// import image9 from "../components/img/image9.jpg"
// import image10 from "../components/img/image10.jpg"
// import image11 from "../components/img/image11.jpg"
// import image12 from "../components/img/image12.jpg"
// import image14 from "../components/img/image14.jpg"
// import image15 from "../components/img/image15.jpg"
// import image16 from "../components/img/image16.jpg"
// import image17 from "../components/img/image17.jpg"
// import image18 from "../components/img/image18.jpg"
// import image19 from "../components/img/image19.jpg"
import image20 from "../components/img/image20.jpg"
import image21 from "../components/img/image21.jpg"
// import image22 from "../components/img/image22.jpg"
import image23 from "../components/img/image23.jpg"
import image24 from "../components/img/image24.jpg"
import image25 from "../components/img/image25.jpg"
import image26 from "../components/img/image26.jpg"
import image27 from "../components/img/image27.jpg"
import image28 from "../components/img/image28.jpg"
import image29 from "../components/img/image29.jpg"
import image30 from "../components/img/image30.jpg"
import image31 from "../components/img/image31.jpg"
import image32 from "../components/img/image32.jpg"
import image33 from "../components/img/image33.jpg"
import image34 from "../components/img/image34.jpg"
import image35 from "../components/img/image35.jpg"
import image36 from "../components/img/image36.jpg"
import image37 from "../components/img/image37.jpg"
import image38 from "../components/img/image38.jpg"
// import image39 from "../components/img/image39.jpg"
// import image40 from "../components/img/image40.jpg"
// import image41 from "../components/img/image41.jpg"
// import image42 from "../components/img/image42.jpg"
// import image43 from "../components/img/image43.jpg"
// import image44 from "../components/img/image44.jpg"
// import image45 from "../components/img/image45.jpg"
// import image46 from "../components/img/image46.jpg"
// import image47 from "../components/img/image47.jpg"
// import image48 from "../components/img/image48.jpg"
// import image49 from "../components/img/image49.jpg"
// import image50 from "../components/img/image50.jpg"
// import image51 from "../components/img/image51.jpg"
// import image52 from "../components/img/image52.jpg"
// import image53 from "../components/img/image53.jpg"
// import image54 from "../components/img/image54.jpg"
// import image55 from "../components/img/image55.jpg"
// import image56 from "../components/img/image56.jpg"
// import image57 from "../components/img/image57.jpg"
// import image58 from "../components/img/image58.jpg"
// import image59 from "../components/img/image59.jpg"
// import image60 from "../components/img/image60.jpg"
// import image61 from "../components/img/image61.jpg"
// import image62 from "../components/img/image62.jpg"
// import image63 from "../components/img/image63.jpg"
// import image64 from "../components/img/image64.jpg"
// import image65 from "../components/img/image65.jpg"
// import image66 from "../components/img/image66.jpg"
// import image67 from "../components/img/image67.jpg"
// import image68 from "../components/img/image68.jpg"
// import image69 from "../components/img/image69.jpg"
// import image70 from "../components/img/image70.jpg"
// import image71 from "../components/img/image71.jpg"
// import image72 from "../components/img/image72.jpg"
// import image73 from "../components/img/image73.jpg"
// import image74 from "../components/img/image74.jpg"
// import image75 from "../components/img/image75.jpg"
// import image76 from "../components/img/image76.jpg"



// import image77 from "../components/img/image77.jpg"
// import image78 from "../components/img/image78.jpg"
// import image79 from "../components/img/image79.jpg"
// import image80 from "../components/img/image80.jpg"
// import image81 from "../components/img/image81.jpg"
// import image82 from "../components/img/image82.jpg"
// import image83 from "../components/img/image83.jpg"
// import image84 from "../components/img/image84.jpg"
// import image85 from "../components/img/image85.jpg"
// import image86 from "../components/img/image86.jpg"
// import image87 from "../components/img/image87.jpg"
// import image88 from "../components/img/image88.jpg"
// import image89 from "../components/img/image89.jpg"
// import image90 from "../components/img/image90.jpg"
// import image91 from "../components/img/image91.jpg"
// import image92 from "../components/img/image92.jpg"
// import image93 from "../components/img/image93.jpg"
// import image94 from "../components/img/image94.jpg"
// import image95 from "../components/img/image95.jpg"
// import image96 from "../components/img/image96.jpg"
// import image97 from "../components/img/image97.jpg"
// import image98 from "../components/img/image98.jpg"
// import image99 from "../components/img/image99.jpg"







const images = [
  { id: 19, src: image20, alt: 'Image 19 description',  },
  { id: 20, src: image21, alt: 'Image 20 description',  },
  { id: 23, src: image23, alt: 'Image 23 description',  },
  { id: 24, src: image24, alt: 'Image 24 description',  },
  { id: 25, src: image25, alt: 'Image 25 description',  },
  { id: 26, src: image26, alt: 'Image 26 description',  },
  { id: 27, src: image27, alt: 'Image 27 description',  },
  { id: 28, src: image28, alt: 'Image 28 description',  },
  { id: 29, src: image29, alt: 'Image 29 description',  },
  { id: 30, src: image30, alt: 'Image 30 description',  },
  { id: 31, src: image31, alt: 'Image 31 description',  },
  { id: 32, src: image32, alt: 'Image 32 description',  },
  { id: 33, src: image33, alt: 'Image 33 description',  },
  { id: 34, src: image34, alt: 'Image 34 description',  },
  { id: 35, src: image35, alt: 'Image 35 description',  },
  { id: 36, src: image36, alt: 'Image 36 description',  },
  { id: 37, src: image37, alt: 'Image 37 description',  },
  { id: 38, src: image38, alt: 'Image 38 description',  },
  // { id: 39, src: image39, alt: 'Image 39 description',  },
  // { id: 40, src: image40, alt: 'Image 40 description',  },
  // { id: 49, src: image49, alt: 'Image 49 description',  },
  // { id: 50, src: image50, alt: 'Image 50 description',  },
  // { id: 51, src: image51, alt: 'Image 51 description',  },
  // { id: 52, src: image52, alt: 'Image 52 description',  },
  // { id: 53, src: image53, alt: 'Image 53 description',  },
  // { id: 54, src: image54, alt: 'Image 54 description',  },
  // { id: 55, src: image55, alt: 'Image 55 description',  },
  // { id: 45, src: image45, alt: 'Image 45 description',  },
  // { id: 46, src: image46, alt: 'Image 46 description',  },
  // { id: 47, src: image47, alt: 'Image 47 description',  },
  // { id: 48, src: image48, alt: 'Image 48 description',  },
  // { id: 56, src: image56, alt: 'Image 56 description',  },
  // { id: 57, src: image57, alt: 'Image 57 description',  },
  // { id: 58, src: image58, alt: 'Image 58 description',  },
  // { id: 59, src: image59, alt: 'Image 59 description',  },
  // { id: 60, src: image60, alt: 'Image 60 description',  },
  // { id: 61, src: image61, alt: 'Image 61 description',  },
  // { id: 62, src: image62, alt: 'Image 62 description',  },
  // { id: 63, src: image63, alt: 'Image 63 description',  },
  // { id: 64, src: image64, alt: 'Image 64 description',  },
  // { id: 65, src: image65, alt: 'Image 65 description',  },
  // { id: 66, src: image66, alt: 'Image 66 description',  },
  // { id: 67, src: image67, alt: 'Image 67 description',  },
  // { id: 68, src: image68, alt: 'Image 68 description',  },
  // { id: 69, src: image69, alt: 'Image 69 description',  },
  // { id: 70, src: image70, alt: 'Image 70 description',  },
  // { id: 71, src: image71, alt: 'Image 71 description',  },
  // { id: 72, src: image72, alt: 'Image 72 description',  },
  // { id: 73, src: image73, alt: 'Image 73 description',  },
  // { id: 74, src: image74, alt: 'Image 74 description',  },
  // { id: 75, src: image75, alt: 'Image 75 description',  },
  // { id: 12, src: image12, alt: 'Image 12 description',  },
  // { id: 76, src: image76, alt: 'Image 76 description',  },


















  // { id: 41, src: image41, alt: 'Image 41 description',  },
  // { id: 42, src: image42, alt: 'Image 42 description',  },
  // { id: 43, src: image43, alt: 'Image 43 description',  },
  // { id: 44, src: image44, alt: 'Image 44 description',  },
  // { id: 22, src: image22, alt: 'Image 22 description',  },
  // { id: 1, src: image1, alt: 'Image 1 description',  },
  // // caption: 'Community Service Event 2016' for captio in down image
  // { id: 2, src: image2, alt: 'Image 2 description',  },
  // { id: 3, src: image3, alt: 'Image 3 description',  },
  // { id: 4, src: image4, alt: 'Image 4 description',  },
  // { id: 5, src: image5, alt: 'Image 5 description',  },
  // { id: 6, src: image6, alt: 'Image 6 description', },
  // { id: 7, src: image7, alt: 'Image 7 description', },
  // { id: 8, src: image8, alt: 'Image 8 description',  },
  // { id: 9, src: image9, alt: 'Image 9 description',  },
  // { id: 10, src: image10, alt: 'Image 10 description', },
  // { id: 11, src: image11, alt: 'Image 11 description', },
  
  // { id: 14, src: image15, alt: 'Image 14 description',  },
  // { id: 15, src: image16, alt: 'Image 15 description',  },
  
  // { id: 16, src: image17, alt: 'Image 16 description',  },

  // { id: 17, src: image18, alt: 'Image 17 description',  },
  // { id: 18, src: image19, alt: 'Image 18 description',  },
  // // { id: 13, src: image14, alt: 'Image 13 description',  },
  // { id: 77, src: image77, alt: 'Image 77 description',  },
  // { id: 78, src: image78, alt: 'Image 78 description' },
  // { id: 79, src: image79, alt: 'Image 79 description' },
  // { id: 80, src: image80, alt: 'Image 80 description' },
  // { id: 81, src: image81, alt: 'Image 81 description' },
  // { id: 82, src: image82, alt: 'Image 82 description' },
  // { id: 83, src: image83, alt: 'Image 83 description' },
  // { id: 84, src: image84, alt: 'Image 84 description' },
  // { id: 85, src: image85, alt: 'Image 85 description' },
  // { id: 86, src: image86, alt: 'Image 86 description' },
  // { id: 87, src: image87, alt: 'Image 87 description' },
  // { id: 88, src: image88, alt: 'Image 88 description' },
  // { id: 89, src: image89, alt: 'Image 89 description' },
  // { id: 90, src: image90, alt: 'Image 90 description' },
  // { id: 91, src: image91, alt: 'Image 91 description' },
  // { id: 92, src: image92, alt: 'Image 92 description' },
  // { id: 93, src: image93, alt: 'Image 93 description' },
  // { id: 94, src: image94, alt: 'Image 94 description' },
  // { id: 95, src: image95, alt: 'Image 95 description' },
  // { id: 96, src: image96, alt: 'Image 96 description' },
  // { id: 97, src: image97, alt: 'Image 97 description' },
  // { id: 98, src: image98, alt: 'Image 98 description' },
  // { id: 99, src: image99, alt: 'Image 99 description' }
  
  


  

  
];

const ImageGallery = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openImageViewer = (index) => {
    setSelectedImageIndex(index);
  };

  const closeImageViewer = () => {
    setSelectedImageIndex(null);
  };

  const showNextImage = () => {
    setSelectedImageIndex((selectedImageIndex + 1) % images.length);
  };

  const showPreviousImage = () => {
    setSelectedImageIndex(
      (selectedImageIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className="gallery-container"><br />
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div key={image.id} className="gallery-item" onClick={() => openImageViewer(index)}>
            <img src={image.src} alt={image.alt} />
            <p className="image-caption">{image.caption}</p>
          </div>
        ))}
      </div>

      {selectedImageIndex !== null && (
        <div className="image-viewer">
          <span className="close" onClick={closeImageViewer}>&times;</span>
          <button className="prev-btn" onClick={showPreviousImage}>&lt;</button>
          <img className="viewer-image" src={images[selectedImageIndex].src} alt={images[selectedImageIndex].alt} />
          <button className="next-btn" onClick={showNextImage}>&gt;</button>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;