// src/Footer.js
import React from 'react';
import './Footer.css';
import FLogo from "../components/img/FLogo.png";
import '@fortawesome/fontawesome-free/css/all.min.css';



const Footer = () => {
  return (
    
    <footer className="footer">
      
      <div className="footer-section contact-us ">
        <img src={FLogo} alt="Zeeva Logo" className="footer-logo" />
        <h5>Maa Sadhana Nasha Mukti Kendra</h5><p className="text-footer-p">
        At Maa Sadhana Nasha Mukti Kendra, we provide a safe and supportive space for individuals struggling with addiction. Our mission is to help them break free from addiction through counseling, rehabilitation, and vocational training. We aim to guide each person towards recovery, self-reliance, and a brighter future. Join us in creating a world free from addiction.</p>
        {/* <p>Phone: <a href="tel:+918377855100">+91-8377855100</a></p>
        <p>Email: <a href="mailto:info@zeeva.in">info@zeeva.in</a></p> */}
      </div>
      <div className="footer-section quick-links"><br />
        <h3>Quick Links</h3>
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="about">About</a></li>
          {/* <li><a href="/vision-mission">Vision - Misssion</a></li>
          <li><a href="/candle-making">Candle Making</a></li> */}
          <li><a href="/image-gallery">Image Gallery</a></li>
          <li><a href="/video-gallery">Video Gallery</a></li>
          <li><a href="/facility">Facility</a></li>
          

          
          {/* <li><a href="#mauritius">Blogs</a></li> */}
          <li><a href="/contact-us">Contact Us</a></li>
          {/* <li><a href="/documents">Documents</a></li> */}
        </ul>
      </div>
      <div className="footer-section opening-hours"><br />
        <h3>Follow Us on Social Media</h3>
        {/* <p>Mon – Fri: 10am to 06pm</p>
        <p>Sat: Doctor On Call</p>
        <p>Sunday : 10am to 06pm</p> */}
        <div className="social-icons">
          <a href="/" aria-label="Facebook" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f "></i></a>

          <a href="https://www.instagram.com/jrdkalyansansthan/" aria-label="Instagram" target="_blank" rel="noopener noreferrer" ><i className="fab fa-instagram ms-2"></i></a>

          {/* <a href="#" aria-label="Twitter" target="_blank"><i className="fab fa-twitter"></i></a> */}
          {/* <a href="#" aria-label="LinkedIn" target="_blank"><i className="fab fa-linkedin-in"></i></a> */}
          <a href="https://www.youtube.com/@JRDKalyansansthan" aria-label="YouTube" target="_blank" rel="noopener noreferrer" ><i className="fab fa-youtube ms-2"></i></a>
          {/* <a href="https://www.google.com/search?kgmid=/g/11vp_f5w43&hl=en-IN&q=Dr.+Vaishali+Tandon&kgs=0b01d5c6f42a5fb0&shndl=30&shem=lrnole,ssic&source=sh/x/loc/osrp/m5/4#lrd=0x3974779af6cb7cef:0xe02e9fe2e36d2145,1,,,," aria-label="Google" target="_blank" rel="noopener noreferrer" ><i className="fab fa-google ms-2"></i></a> */}

        </div>
      </div>
      
      <div className="footer-section opening-hours"><br />
        <h3>Conatcts Us</h3>
        <a rel="nofollow" className="component-target" href="tel:+(91)-07535807012">
          <i className="fa fa-phone"></i>
          <span className="component-target-text"> +(91)-07535807012</span>
          
       </a><br />
        {/* <a  href="mailto:jrdkalyansansthan@gmail.com"><br /><i className="fas fa-envelope"></i> jrdkalyansansthan@gmail.com</a> */}
        <div className="footer-section location text-start ms-0"><br />
        <h3>Location</h3> 
        <p>Visit us at our location:</p>
        <span>
          {/* <a href="https://www.google.com/maps/place/Dr.+Vaishali+Tandon/@27.2043453,77.9920107,17z/data=!4m14!1m7!3m6!1s0x3974779af6cb7cef:0xe02e9fe2e36d2145!2sDr.+Vaishali+Tandon!8m2!3d27.2043453!4d77.9945856!16s%2Fg%2F11vp_f5w43!3m5!1s0x3974779af6cb7cef:0xe02e9fe2e36d2145!8m2!3d27.2043453!4d77.9945856!16s%2Fg%2F11vp_f5w43?hl=en-IN&entry=ttu" aria-label="Location" target="_blank" rel="noopener noreferrer"> */}
          <p><span>
          <i className="fas fa-map-marker-alt " ></i>  NH-2, Delhi - Mathura Hwy, near Heera lal ki Pyau, Sikandra, Agra, Uttar Pradesh 282007</span></p>
          {/* </a> */}
          </span>
      </div>
     
      

        
       
      </div>
      <div className="copyright ">
      <p className='text-whitesmoke'>©Maa Sadhana Nasha Mukti Kendra 2024 | All rights reserved. Design and Developed by <span><a href="https://www.magnumdigitalsolution.com/" target="_blank" rel="noreferrer noreferrer"> Magnum Digital Solutions </a></span></p>
      </div>
     
      
      
      
    </footer>
    
    
  );
};

export default Footer;
