import React from 'react';
import './FertilitySection.css';
import { useInView } from 'react-intersection-observer';

import Donate from "./img/Donate.avif"
// import { Link } from "react-router-dom";


const FertilitySection = () => {
  const { ref: box1Ref, inView: box1InView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: box2Ref, inView: box2InView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className="container">
      <div ref={box1Ref} className={`box ${box1InView ? 'animate' : ''}`}>
        <div className="vc_column-inner vc_custom_1702970911176">
          <div className="wpb_wrapper">
            <div className="wpb_single_image wpb_content_element vc_align_center">
              <figure className="wpb_wrapper vc_figure">
                <div className="vc_single_image-wrapper vc_box_border_grey">
                  <img
                    decoding="async"
                    className="vc_single_image-img"
                    src={Donate}
                    alt="fertility"
                    title="fertility"
                    loading="lazy"
                  />
                </div>
              </figure>
            </div>
            <div className="wpb_text_column wpb_content_element">
              <div className="wpb_wrapper">
                <h4 className="entry-title" style={{ textAlign: 'center' }}><b>The Negative Effects of Alcohol Consumption</b></h4>
                <p className='text-start' style={{ textAlign: 'center', color: 'grey' }}>
                Excessive alcohol consumption can have several harmful effects on your health and overall well-being. Here are some of the key risks associated with drinking alcohol: <br /> <br />Health Issues:
Drinking alcohol in large quantities can lead to serious health problems:

Liver Damage: Alcohol puts excessive strain on the liver, leading to conditions like fatty liver, alcoholic hepatitis, cirrhosis, and liver cancer.
Heart Problems: Regular alcohol consumption increases the risk of high blood pressure, heart disease, irregular heartbeats (arrhythmias), and stroke.
Brain Damage: Long-term drinking affects brain function, leading to memory problems, cognitive decline, and difficulty in learning and decision-making.
Weakened Immune System: Alcohol reduces the body’s ability to fight infections, making individuals more susceptible to illnesses. <br /><br />Addiction:
Alcohol can be highly addictive, leading to alcoholism, a chronic condition where individuals cannot control their alcohol consumption. This addiction can severely affect relationships, work life, and overall health. Over time, alcohol dependence may lead to severe physical and mental health complications. <br /> <br />Mental Health Issues:
Drinking alcohol is closely linked to several mental health problems:

Depression and Anxiety: Alcohol is often used as a coping mechanism for stress, but it can worsen feelings of depression and anxiety. It disrupts the balance of chemicals in the brain, making it harder to manage emotions.
Poor Judgment and Aggression: Alcohol impairs judgment, leading to risky behavior, poor decision-making, and increased aggression. This can result in accidents, violence, and regrettable actions. <br /><br />Impact on Relationships:
Excessive drinking can strain relationships with family, friends, and colleagues. It may lead to arguments, misunderstandings, and even violence. Drunk driving, alcohol-induced accidents, or aggressive behavior can also cause irreparable damage to personal relationships. <br /><br /> Social Consequences:
Alcohol not only affects the individual but also impacts society:

Drunk Driving: Alcohol is a leading cause of traffic accidents and fatalities. Driving under the influence poses a significant risk not only to the driver but also to passengers and others on the road.
Legal and Financial Problems: Alcohol-related behavior can lead to legal issues, such as fines, arrests, or lawsuits. In addition, the financial burden of medical treatment for alcohol-related health problems or legal fees can create long-term financial strain. <br /><br />Increased Risk of Cancer:
Drinking alcohol has been linked to a higher risk of several types of cancer, including cancers of the mouth, throat, liver, breast, and colon. The risk increases with the amount and frequency of alcohol consumed.
</p>

               

              </div>
              {/* <Link to="/" className="btn btn-warning ms-2 link-button">Donate Now</Link><br /> */}
            </div>
          </div>
        </div>
      </div>

      <div ref={box2Ref} className={`box ${box2InView ? 'animate' : ''}`}>
        <div className="vc_column-inner vc_custom_1702970911176">
          <div className="wpb_wrapper">
            <div className="wpb_single_image wpb_content_element vc_align_center">
              <figure className="wpb_wrapper vc_figure">
                <div className="vc_single_image-wrapper vc_box_border_grey">
                
                </div>
              </figure>
            </div>
            <div className="wpb_text_column wpb_content_element">
              <div className="wpb_wrapper">
              <p className='text-start' style={{ textAlign: 'center', color: 'grey' }}>
              <br /><br />Increased Risk of Cancer:
Drinking alcohol has been linked to a higher risk of several types of cancer, including cancers of the mouth, throat, liver, breast, and colon. The risk increases with the amount and frequency of alcohol consumed. <br /><br />Sleep Disturbances:
While alcohol may initially make you feel drowsy and help you fall asleep faster, it disrupts the natural sleep cycle. Alcohol reduces the quality of sleep, preventing deep and restorative rest, leading to fatigue and poor mental performance the next day. <br /><br />Weight Gain:
Alcohol is high in calories and low in nutrients. Drinking too much alcohol can lead to weight gain and contribute to obesity, further increasing the risk of various health problems like diabetes, high blood pressure, and joint issues. <br /><br />Digestive Issues:
Alcohol can irritate the digestive system, leading to problems such as gastritis, acid reflux, ulcers, and pancreatitis. Chronic drinking can also affect nutrient absorption in the gut, leading to deficiencies in essential vitamins and minerals. <br /><br />Increased Risk of Accidents:
Alcohol impairs motor coordination and reaction time, increasing the likelihood of accidents at home, work, or in public spaces. These accidents may involve falls, injuries, or even fatalities.
</p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FertilitySection;
