import React from 'react';
import './About.css';
import { motion } from 'framer-motion'; // Importing framer-motion for animations
import Detox from "./img/Detox.jpg";
import Inpatient from "./img/Inpatient.webp";
import Personalized from "./img/Personalized.jpg";
import Family from "./img/Family.jpg";
import Aftercare from "./img/Aftercare.webp";
import Motivational from "./img/Motivational.jpeg";
import Holistic from "./img/Holistic.jpg";

const  AboutBanner = () => {
  return (
    <div className="about-container">
      {/* Hero Section */}
      <motion.div 
        className="hero-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h1 className="hero-title">Welcome to Maa Sadhana Nasha Mukti Kendra</h1>
        <p className="hero-subtitle">Helping You Start a New, Healthy, and Addiction-Free Life</p>
        <button className="hero-button">Explore Our Programs</button>
      </motion.div>

      {/* About Section */}
      <motion.section 
        className="about-section"
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.7 }}
      >
        <h2>About Us</h2>
        <p>
          <strong>Maa Sadhana Nasha Mukti Kendra</strong> is dedicated to providing compassionate and holistic treatment to help individuals struggling with addiction reclaim their lives. Our team consists of experienced counselors, doctors, and support staff who understand the challenges of addiction recovery and are committed to guiding individuals towards a healthier lifestyle.
        </p>
      </motion.section>

      {/* Our Mission and Vision Section */}
      <motion.section 
        className="mission-vision-section"
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <h2>Our Mission & Vision</h2>
        <p>
          Our mission is to create a supportive and nurturing environment that empowers individuals to overcome addiction. We envision a world where everyone has access to quality treatment and the opportunity to lead an addiction-free life.
        </p>
      </motion.section>

      {/* Our Programs Section */}
      <section className="programs-section">
        <h2>Our Programs</h2>
        <p className="programs-intro">
          We offer a range of specialized programs designed to meet the unique needs of each individual. From detox to aftercare, each program is tailored to provide holistic support throughout the recovery journey.
        </p>
        <div className="program-card-wrapper">
          {/* Detoxification */}
          <motion.div 
            className="program-card"
            whileHover={{ scale: 1.05 }}
          >
            <img src={Detox} alt="Detox Program" />
            <h3>Detoxification</h3>
            <p>A medically supervised detox program to safely manage withdrawal symptoms and prepare for recovery.</p>
          </motion.div>

          {/* Inpatient Care */}
          <motion.div 
            className="program-card"
            whileHover={{ scale: 1.05 }}
          >
            <img src={Inpatient} alt="Inpatient Care" />
            <h3>Inpatient Care</h3>
            <p>24/7 support with structured routines, therapy, and group sessions in a safe environment.</p>
          </motion.div>

          {/* Personalized Counseling */}
          <motion.div 
            className="program-card"
            whileHover={{ scale: 1.05 }}
          >
            <img src={Personalized} alt="Counseling Services" />
            <h3>Personalized Counseling</h3>
            <p>One-on-one sessions with expert counselors to address emotional and psychological aspects of recovery.</p>
          </motion.div>

          {/* Family Therapy */}
          <motion.div 
            className="program-card"
            whileHover={{ scale: 1.05 }}
          >
            <img src={Family} alt="Family Therapy" />
            <h3>Family Therapy</h3>
            <p>Support for family members, fostering understanding and a positive environment for recovery.</p>
          </motion.div>

          {/* Aftercare Support */}
          <motion.div 
            className="program-card"
            whileHover={{ scale: 1.05 }}
          >
            <img src={Aftercare} alt="Aftercare Support" />
            <h3>Aftercare Support</h3>
            <p>Continued support and resources post-treatment to help maintain long-term sobriety.</p>
          </motion.div>

          {/* Additional Programs */}
          <motion.div 
            className="program-card"
            whileHover={{ scale: 1.05 }}
          >
            <img src={Motivational} alt="Motivational Programs" />
            <h3>Motivational Programs</h3>
            <p>Programs to rebuild self-esteem, boost confidence, and reinforce commitment to a healthier lifestyle.</p>
          </motion.div>
          <motion.div 
            className="program-card"
            whileHover={{ scale: 1.05 }}
          >
            <img src={Holistic} alt="Holistic Therapy" />
            <h3>Holistic Therapy</h3>
            <p>Incorporating yoga, meditation, and mindfulness to promote physical, mental, and emotional healing.</p>
          </motion.div>
        </div>
      </section>

    

      {/* Contact Section
      <section className="contact-section">
        <h2>Get in Touch</h2>
        <p>If you or someone you care about is struggling with addiction, reach out to us. Our team is here to provide help and guidance every step of the way.</p>
        <button className="contact-button">Contact Us Today</button>
      </section> */}
    </div>
  );
};
export default  AboutBanner;



