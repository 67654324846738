import React, { useEffect } from 'react';
import './VideoPage.css';

const VideoPage = () => {
  useEffect(() => {
    const videos = document.querySelectorAll('.video');

    const scrollHandler = () => {
      videos.forEach(video => {
        const videoTop = video.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (videoTop < windowHeight * 0.75) {
          video.classList.add('animate');
        }
      });
    };

    window.addEventListener('scroll', scrollHandler);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return (
    <div className="video-page">
      <div className="video-container">
        {/* Video in public folder */}
        <video className="video" width="360" height="215" controls autoPlay>
          <source src="/videos/video1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <video className="video" width="360" height="215" controls autoPlay>
          <source src="/videos/video2.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video className="video" width="360" height="215" controls autoPlay>
          <source src="/videos/video3.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video className="video" width="360" height="215" controls autoPlay>
          <source src="/videos/video4.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video className="video" width="360" height="215" controls autoPlay>
          <source src="/videos/video5.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video className="video" width="360" height="215" controls autoPlay>
          <source src="/videos/video6.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video className="video" width="360" height="215" controls autoPlay>
          <source src="/videos/video7.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video className="video" width="360" height="215" controls autoPlay>
          <source src="/videos/video8.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        
        <video className="video" width="360" height="215" controls autoPlay>
          <source src="/videos/video9.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video className="video" width="360" height="215" controls autoPlay>
          <source src="/videos/video10.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video><video className="video" width="360" height="215" controls autoPlay>
          <source src="/videos/video11.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video><video className="video" width="360" height="215" controls autoPlay>
          <source src="/videos/video12.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        
        

        {/* Add more videos similarly */}
      </div>
    </div>
  );
};

export default VideoPage;
